import { linkBridge } from "@webview-bridge/web";
import { AppBridge } from "./bridge.output";

const bridge = linkBridge<AppBridge>({
  onReady: async (method) => {
    console.log("bridge is ready");
  },
});

function useNativeAppBridge() {
  return bridge;
}

export default useNativeAppBridge;
