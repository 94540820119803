import { OptionValue } from "@/services/types";

const useFilterOptions = (optionValue: OptionValue | undefined) => {
  const listOptionValue = optionValue?.data.results ?? [];

  return listOptionValue.map(({ id, value, color }) => {
    const textColor = color && color !== ("" || "#ffffff") ? color : "#000";
    const backgroundColor = color && color !== "" ? `${color}1A` : "#FAFAFA";
    const borderColor = color && color !== ("" || "#ffffff") ? `${color}80` : "#000";

    return {
      value: id,
      label: (
        <div
          style={{
            fontSize: "12px",
            padding: "5px 5px",
            backgroundColor: backgroundColor,
            height: "20px",
            display: "inline-flex",
            justifyContent: "center",
            alignItems: "center",
            color: textColor,
            border: `1px solid ${borderColor}`,
            borderRadius: "2px",
            paddingLeft: "5px",
            paddingRight: "5px",
          }}
        >
          {value}
        </div>
      ),
    };
  });
};

export default useFilterOptions;
