import { PropertyType } from "@/services/types";
import { SELECT_FILTER_TYPES } from "../constants";
import OptionFilter from "./filter/OptionFilter";
import UserFilter from "./filter/UserFilter";
import { Col, DatePicker, Form, FormInstance, Input, Row, Select } from "antd";
import { Rule } from "antd/es/form";
import EnterpriseFilter from "./filter/EnterpriseFilter";

type ContactFormProps = {
  propertyList: PropertyType[];
  onFinish: (formValues: Record<string, string | string[]>) => void;
  form: FormInstance<any>;
  initialValues?: Record<string, string | string[]>;
  colSpan?: number;
  disabled?: boolean;
};

const fieldRender = (property: PropertyType, form) => {
  if (property) {
    const type = property?.type;
    const slug = property?.slug;
    let rules: Rule[] = [
      {
        required: !property.allow_blank,
      },
    ];

    if (slug === "email") {
      rules = [
        ...rules,
        {
          pattern: new RegExp(
            /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i
          ),
          message: "Hãy nhập email đúng",
        },
      ];
    }
    if (slug === "phone_number") {
      rules = [
        ...rules,
        {
          pattern: new RegExp(/^(\+?84|0)[0-9\s]{9,}$/),
          message: "Hãy nhập số điện thoại đúng",
        },
      ];

      return (
        <Form.Item name={slug} label={property.name} rules={rules}>
          <Input
            style={{ minWidth: 230 }}
            placeholder={property.name}
            onChange={(e) => {
              const value = e.target.value?.replace(/[^0-9+]/g, "");
              form.setFieldValue(slug, value);
            }}
          />
        </Form.Item>
      );

    }

    if (slug === "company") {
      return (
        <EnterpriseFilter
          label={property.name}
          property={property}
          placeholder={property.name}
          formItemName={slug}
          style={{ minWidth: 230 }}
          hasMargin={true}
          rules={rules}
        />
      );
    } else if (SELECT_FILTER_TYPES.includes(type)) {
      const isMutiple =
        type === "multioptionproperty"
          ? {
              mode: "multiple" as "multiple",
              maxTagCount: "responsive" as "responsive",
            }
          : {};
      return (
        <OptionFilter
          label={property.name}
          property={property}
          placeholder={property.name}
          formItemName={slug}
          style={{ minWidth: 230 }}
          hasMargin={true}
          rules={rules}
          {...isMutiple}
        />
      );
    } else if (type === "singleuserproperty") {
      return (
        <UserFilter
          label={property.name}
          property={property}
          placeholder={property.name}
          formItemName={slug}
          style={{ minWidth: 230 }}
          hasMargin={true}
          rules={rules}
        />
      );
    } else if (type === "multiuserproperty") {
      return (
        <UserFilter
          label={property.name}
          property={property}
          placeholder={property.name}
          formItemName={slug}
          style={{ minWidth: 230 }}
          mode="multiple"
          maxTagCount="responsive"
          hasMargin={true}
          rules={rules}
        />
      );
    } else if (type === "dateproperty") {
      return (
        <Form.Item name={slug} label={property.name} rules={rules}>
          <DatePicker
            placeholder={property.name}
            style={{ minWidth: 230, width: "100%" }}
            format={"DD-MM-YYYY"}
          />
        </Form.Item>
      );
    } else if (type === "integerproperty") {
      return (
        <Form.Item name={slug} label={property.name} rules={rules}>
          <Input style={{ minWidth: 230 }} placeholder={property.name} />
        </Form.Item>
      );
    } else {
      return (
        <Form.Item name={slug} label={property.name} rules={rules}>
          <Input style={{ minWidth: 230 }} placeholder={property.name} />
        </Form.Item>
      );
    }
  }
  return null;
};

const ContactForm = (props: ContactFormProps) => {
  const { propertyList, onFinish, form, initialValues, colSpan = 12, disabled } = props;

  const restProperty = propertyList.filter(
    (property) =>
      (property.allow_in_form || property.default_in_form) && !property.is_auto
      && !["crmorder", "crmdeal", "ticket"].includes(property.slug)
  );
  return (
    <Form
      layout="vertical"
      onFinish={onFinish}
      form={form}
      initialValues={initialValues}
      disabled={disabled}
      >
      <Row gutter={16}>
        {restProperty.map((property) => (
          <Col className="gutter-row" span={colSpan} key={property.slug}>
            {fieldRender(property, form)}
          </Col>
        ))}
      </Row>
    </Form>
  );
};

export default ContactForm;
