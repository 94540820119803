import {
  Avatar,
  Button,
  Divider,
  Form,
  Input,
  InputNumber,
  message,
  Progress,
  Select,
  Space,
  Switch,
  Tag,
} from "antd";
import React, { useEffect, useMemo } from "react";
import {
  SyncOutlined,
  PhoneOutlined,
  LoadingOutlined,
  CopyOutlined,
} from "@ant-design/icons";
import styled from "styled-components";
import { useTranslations } from "next-intl";
import { usePhoneFeatures } from "@/providers/app/PhoneFeaturesProvider";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useEnvironment } from "@/providers/app/EnvironmentProvider";

interface IProps {
  phoneNumber?: string;
  extraHeaders?: string[];
}

function BeforeCall({ phoneNumber, extraHeaders }: IProps) {
  const currentPath = window.location.pathname;
  const t = useTranslations("call");
  const {
    checklist,
    toggleReadyToReceiveCall,
    readyToReceiveCall,
    phoneOptions,
    toggleCallWaiting,
    toggleAutoAnswer,
  } = usePhoneFeatures();
  const [form] = Form.useForm();
  const { PHONE_IFRAME_SYNC_SPEED } = useEnvironment();
  const { register } = checklist;
  const { callNumber } = usePhoneFeatures();
  const { mutateAsync: toggleDND, isPending: isTogglingDND } = useMutation({
    mutationFn: async () => {
      toggleReadyToReceiveCall();
      await new Promise((resolve) =>
        setTimeout(resolve, PHONE_IFRAME_SYNC_SPEED / 2)
      );
    },
  });
  const { mutateAsync: toggleAA, isPending: isTogglingAA } = useMutation({
    mutationFn: async () => {
      toggleAutoAnswer();
      await new Promise((resolve) =>
        setTimeout(resolve, PHONE_IFRAME_SYNC_SPEED / 2)
      );
    },
  });
  const { mutateAsync: toggleCW, isPending: isTogglingCW } = useMutation({
    mutationFn: async () => {
      toggleCallWaiting();
      await new Promise((resolve) =>
        setTimeout(resolve, PHONE_IFRAME_SYNC_SPEED / 2)
      );
    },
  });

  const progress = useMemo(() => {
    return (
      (Object.values(checklist).filter((e) => e.ready).length /
        Object.values(checklist).length) *
      100
    );
  }, [checklist]);

  // Nếu trong chiến dịch telesales thì sẽ disable Switch auto_answer và call_waiting
  const isTelesalesCampaign = useMemo(() => {
    return currentPath.includes("call_telesales");
  }, [currentPath]);

  const { isPending: fakeLoadingTimer, mutate } = useMutation({
    mutationFn: async () => {
      await new Promise((resolve) =>
        setTimeout(resolve, PHONE_IFRAME_SYNC_SPEED / 2)
      );
      return true;
    },
  });

  const { mutateAsync: callWrapped, isPending: isCalling } = useMutation({
    mutationFn: (phone_number: string) => {
      callNumber(phone_number, extraHeaders);
      return new Promise((resolve) =>
        setTimeout(resolve, PHONE_IFRAME_SYNC_SPEED)
      );
    },
  });

  const onFormFinish = (values) => {
    const { phone_number } = values;
    callWrapped(phone_number);
  };

  const phoneNumberValue = Form.useWatch("phone_number", form);
  const handleCopyPhoneNumber = async () => {
    if (phoneNumberValue && phoneNumberValue.length > 0) {
      await navigator.clipboard.writeText(phoneNumberValue);
      message.success(t("copyPhoneNumberSuccess"));
    }
  };

  useEffect(() => {
    mutate();
  }, [mutate]);

  useEffect(() => {
    form.setFieldValue("phone_number", phoneNumber || "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phoneNumber]);
  return (
    <>
      <Space direction="vertical" className="w-100 pt-2 px-2">
        <div>
          <span>{t("status")}</span>
          <Tag className="ms-2" color={register.color}>
            {register.mapped_status}
          </Tag>
        </div>
        <div className="d-flex justify-content-between">
          <span>{t("ready_to_receive_call")}</span>
          <Switch
            disabled={register.original_status === "waiting"}
            loading={register.original_status === "waiting" || isTogglingDND}
            checked={readyToReceiveCall}
            onClick={() => {
              toggleDND();
            }}
          />
        </div>
        <div className="d-flex justify-content-between">
          <span>Auto Answer</span>
          <Switch
            disabled={
              isTelesalesCampaign || register.original_status === "waiting"
            }
            loading={register.original_status === "waiting" || isTogglingAA}
            checked={phoneOptions.autoAnswer}
            onClick={() => {
              toggleAA();
            }}
          />
        </div>
        <div className="d-flex justify-content-between">
          <span>Call Waiting</span>
          <Switch
            disabled={
              isTelesalesCampaign || register.original_status === "waiting"
            }
            loading={register.original_status === "waiting" || isTogglingCW}
            checked={phoneOptions.callWaiting}
            onClick={() => {
              toggleCW();
            }}
          />
        </div>
        <div className="d-flex justify-content-between">
          <span>{t("incoming_phone_number")}</span>
          <Select className="col-6" size="small" placeholder="Số chung" />
        </div>
      </Space>
      <Divider className="my-3" />
      <Space direction="vertical" className="w-100 px-2">
        <div className="d-flex justify-content-between align-items-baseline">
          <h6 style={{ textTransform: "uppercase" }}>{t("condition_call")}</h6>
          {fakeLoadingTimer && (
            <Button loading={fakeLoadingTimer} icon={<SyncOutlined />} />
          )}
        </div>
        <Progress
          status={
            fakeLoadingTimer
              ? "active"
              : progress === 100
                ? "success"
                : "normal"
          }
          percent={progress}
        />
        <div>
          <Tag color={checklist.register.ready ? "green" : "red"}>
            {checklist.register.ready
              ? t("register.ready")
              : t("register.failed")}
          </Tag>
          <span>{t("check_network")}</span>
        </div>
        <div>
          <Tag color={checklist.microphone.ready ? "green" : "red"}>
            {checklist.microphone.ready
              ? t("microphone.ready")
              : t("microphone.not_found")}
          </Tag>
          <span>{t("check_micro")}</span>
        </div>
        {/*<div>*/}
        {/*  <Tag color="orange">{t("check")}</Tag>*/}
        {/*  <span>{t("check_sample_call")}</span>*/}
        {/*</div>*/}
      </Space>
      <Divider className="my-3" />
      <Form onFinish={onFormFinish} form={form} layout="vertical">
        <Space direction="vertical" className="w-100 px-2">
          <p className="m-0">{t("suggestion_list")}</p>
          {/*<Space direction="vertical" className="w-100">*/}
          {/*  <div className="d-flex justify-content-between align-items-center">*/}
          {/*    <div>*/}
          {/*      <Avatar size="small">U</Avatar>*/}
          {/*      <span className="ms-2">*/}
          {/*        Nguyễn Quân*/}
          {/*        <Divider className="mx-1" type="vertical" />*/}
          {/*        0989266598*/}
          {/*      </span>*/}
          {/*    </div>*/}
          {/*    <Button size="small" ghost type="primary">*/}
          {/*      {t("detail")}*/}
          {/*    </Button>*/}
          {/*  </div>*/}
          {/*  <div className="d-flex justify-content-between align-items-center">*/}
          {/*    <div>*/}
          {/*      <Avatar size="small">U</Avatar>*/}
          {/*      <span className="ms-2">*/}
          {/*        Nguyễn Quân*/}
          {/*        <Divider className="mx-1" type="vertical" />*/}
          {/*        0989266598*/}
          {/*      </span>*/}
          {/*    </div>*/}
          {/*    <Tag className="m-0" color="blue">*/}
          {/*      {t("internal")}*/}
          {/*    </Tag>*/}
          {/*  </div>*/}
          {/*</Space>*/}
          <Form.Item
            name="phone_number"
            rules={[
              {
                required: true,
                message: t("phone_number_required"),
              },
            ]}>
            <Input
              placeholder={t("phone_number_placeholder")}
              suffix={<CopyOutlined onClick={handleCopyPhoneNumber} />}
            />
          </Form.Item>
        </Space>
        <div className="pb-2 px-2">
          <Button
            className="mt-3 w-100"
            type="primary"
            htmlType="submit"
            loading={isCalling}
            icon={<PhoneOutlined />}>
            {t("call")}
          </Button>
        </div>
      </Form>
    </>
  );
}

export default BeforeCall;
